import { LayoutModule } from '@angular/cdk/layout';
import {
  Component,
  ChangeDetectionStrategy,
  inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { NavigationServiceToken } from '../navigation';
import { HeaderService } from '@cca-common/page-title';
import { IconComponent, UiNumericalBadgeComponent } from '@cca/ui';
import { HeaderMenuComponent } from '../header-menu';
import {
  AuthenticationFacade,
  PermissionService,
} from '@cca-common/authentication';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationsPanelComponent } from '@cca-common/notifications';
import { NotificationsStore } from '@cca-common/notifications';
import { MatButtonModule } from '@angular/material/button';
import {
  ChaincargoApiConfigToken,
  PlatformEnvironmentToken,
  WINDOW,
} from '@cca-environment';
import { LanguageSwitchComponent } from '@cca-common/translations';
import { LetDirective } from '@ngrx/component';
import { TranslocoModule } from '@jsverse/transloco';
import {
  devMenuEnabled,
  DevMenuToggleComponent,
} from '@cca-common/feature-dev';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HubSpotConversations: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hsConversationsSettings: any;
  }
}

@Component({
  standalone: true,
  selector: 'cca-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatBadgeModule,
    MatButtonModule,
    MatMenuModule,
    PushPipe,
    LayoutModule,
    IconComponent,
    HeaderMenuComponent,
    LanguageSwitchComponent,
    NotificationsPanelComponent,
    UiNumericalBadgeComponent,
    LetDirective,
    TranslocoModule,
    DevMenuToggleComponent,
  ],
})
export class HeaderComponent {
  private window = inject(WINDOW);
  protected environment = inject(PlatformEnvironmentToken);
  public notificationsStore = inject(NotificationsStore);
  permissions = inject(PermissionService);

  devMenuEnabled = devMenuEnabled;

  @Output() notificationPanelToggle = new EventEmitter<void>();

  public headerService = inject(HeaderService, {
    optional: true,
  });

  public navService = inject(NavigationServiceToken, {
    optional: true,
  });

  private chaincargoEnvironment = inject(ChaincargoApiConfigToken, {
    optional: true,
  });

  private authentication = inject(AuthenticationFacade, {
    optional: true,
  });

  get isChaincargoPlatform() {
    return !!this.chaincargoEnvironment;
  }

  get hubspotExist() {
    return this.window?.HubSpotConversations?.widget;
  }

  get isLoggedIn() {
    return !!(this.authentication && this.authentication.authenticated());
  }

  protected onContactSupport() {
    const status = this.window?.HubSpotConversations?.widget?.status();

    if (!status?.loaded) {
      this.window?.HubSpotConversations?.widget?.load({ widgetOpen: true });
    } else {
      this.window?.HubSpotConversations?.widget?.remove();
    }
  }
}
